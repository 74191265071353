.content_accueil
{
  display: flex;
  justify-content: space-around;
  padding: 50px;
}

.text_accueil p
{
  text-align: justify;
  padding: 20px;
  line-height: 150%;
}
.img_accueil
{
  padding: 20px;
}
.logo_suspendus_accueil
{
  max-width: 100%;
}
.conteneur_img_accueil
{
  display: flex;
  align-items: center;
  justify-content: center;
}