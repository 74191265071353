*
{
  margin: 0;
  padding: 0;
  font-family: "Roboto Bk", "Open Sans", Arial, serif;
  Overflow-x: inherit;
}
.title
{
  color: #487367;
  font-size: 40px;
  display: flex;
  justify-content: center;
  padding: 30px;
}
.under_title
{
  padding: 5px;
  margin: 5px;
  font-size: 25px;
  color: #487367;
  font-weight: bolder;
}
.letter
{
  font-size: 22px;
  color: #487367;
  font-weight: bold;
}
.link
{
  display: inline-block;
  text-decoration: none;
  color: #68A694;
  font-weight: bold;
  font-size: 19px;
  transition-duration: 0.5s;
}

.link:hover
{
  transform: scale(1.1,1.1);
  transition-duration: 0.5s;
}
.center
{
  display: flex;
  align-items: center;
  justify-content: center;
}
.fondBlancCasse{
  background-color: #f0f0f0;
  height: 100%;
  border: none;
}
.fondGrisClair{
  background-color: #c7c7c7;
  height: 100%;
}

figcaption
{
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #888886;
  font-size: 15px;
}
.link:hover
{
  transform: scale(1.1,1.1);
  transition-duration: 0.5s;
  text-decoration: none;
}
.link
{
  transition-duration: 0.5s;
}
.borderArrondi{
  border-radius: 10px;

  border: solid 1.5px rgba(64,64,62,0.4);
}