.h3_init
{
  align-content: center;
  color: #487367;
  font-size: 23px;

}
.content_init
{
  display: flex;
  padding: 50px;
  text-align: justify;
}

p.text_init
{
  line-height: 150%;
}

.center
{
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_doc
{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 70px;
}



