.plan{
  height: 100%;
  width: 100%;
}
.iframe{
  width: 100%;
  height: 100%;
  padding: 2% 2% 2% 2%;
}
.text_contact{
  line-height: 150%;
}