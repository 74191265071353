.nav{
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(to right, #5B5B5B, #40403E);
  z-index: 100;
}
.navBarGauche{
  color:#ffff
}
.navText{
  color:#c7c7c7;
  margin: 0% 40% 0% 40%
}
.active{
  color: #f0f0f0;
  text-decoration: underline;
}
.dropMenu {
  color:#c7c7c7;
}